<template>
  <div class="view" :style="style">
    <div class="view-body">
      <div class="row">
        <div
          :style="headerStyles"
          v-if="order.header && Number(order.header)"
          :class="`col-12 view-body__header ${checkOrder('header')}`"
        >
          <slot name="header"></slot>
        </div>
        <div
          v-if="order.body && Number(order.body)"
          :class="`col view-body__content ${checkOrder('body')}`"
        >
          <slot name="body"></slot>
        </div>
        <div
          v-if="order.sidebar && Number(order.sidebar)"
          :style="[toggler ? `top: ${headerHeight}px` : '']"
          :class="[
            `col view-body__sidebar ${checkOrder('sidebar')}`,
            {
              sidebar_toggle: toggler,
              sidebar_show: toggler && sidebarToggleShow,
            },
          ]"
        >
          <slot name="sidebar"></slot>
        </div>
        <div
          v-if="toggler"
          class="drop_shadow"
          :style="`top: ${headerHeight}px`"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditPageContainer",
  data() {
    return {
      headerHeight: 0,
      toggler: false,
      style: {
        "--sidebar-width": this.sidebarWidth,
        "--sidebar-height": this.sidebarHeight,
      },
    };
  },
  props: {
    order: {
      type: Object,
      default: () => {
        return { header: "1", body: "2", sidebar: "3" };
      },
    },
    sidebarWidth: {
      type: String,
      default: "362px",
    },
    sidebarHeight: {
      type: String,
      default: "calc(100vh - 74px)",
    },
    headerStyles: {
      type: Object,
      default: () => {},
    },
    sidebarCanToggle: {
      type: Boolean,
      default: false,
    },
    sidebarToggleShow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    checkOrder(key) {
      let res = "";

      if (key in this.order) {
        let data = this.order[key];

        if (Array.isArray(data)) {
          // example: ['order-1', 'order-md-2']
          data.forEach((el) => (res += ` ${el} `));
        } else if (typeof data === "object" && data !== null) {
          let breakpoints = ["xs", "sm", "md", "lg", "xl"];

          // example: { sm: 1, md: 2 }
          for (let bKey in data) {
            if (breakpoints.includes(bKey)) {
              // xs means from smallest screen for example on 320px
              if (bKey === "xs") res += ` order-${data[bKey]} `;
              else res = ` order-${bKey}-${data[bKey]} `;
            }
          }
        } else if (["string", "number"].includes(typeof data)) {
          res = "order-" + data;
        }
      }

      return res;
    },
    handleResize() {
      let head = document.querySelector("#head");

      if (head) this.headerHeight = head.offsetHeight;

      this.toggler = window.matchMedia("(max-width: 1200px)").matches
        ? true
        : false;
    },
  },
  watch: {
    sidebarWidth() {
      this.style["--sidebar-width"] = this.sidebarWidth;
    },
  },
  mounted() {
    if (this.sidebarCanToggle) {
      this.handleResize();
      window.addEventListener("resize", this.handleResize);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.view {
  &-body {
    &__content {
      margin-bottom: 30px !important;

      @media (min-width: 1200px) {
        flex: 0 0 calc(100% - var(--sidebar-width));
        max-width: calc(100% - var(--sidebar-width));
        margin-bottom: 0px !important;
      }

      ::v-deep > div:not([style*="display: none"]):last-of-type {
        margin-bottom: 0px !important;
      }
    }
    &__sidebar {
      margin-bottom: 30px !important;

      @media (min-width: 1200px) {
        flex: 0 0 var(--sidebar-width);
        max-width: var(--sidebar-width);
        position: sticky;
        top: 70px;
        height: var(--sidebar-height);
        overflow: auto;
        margin-bottom: 0px !important;

        &::-webkit-scrollbar {
          width: 0;
        }
      }

      ::v-deep > div:not([style*="display: none"]):last-child {
        margin-bottom: 0px !important;
      }

      &.sidebar_toggle {
        margin: 0 !important;
        flex: 0 0 var(--sidebar-width);
        max-width: var(--sidebar-width);
        position: fixed;
        left: -100%;
        top: 0;
        bottom: 0;
        transition: all 0.3s ease;
        z-index: 5;
        overflow: auto;
        margin: 0;
        padding: 20px;
        background: $background;

        &::-webkit-scrollbar {
          width: $scrollbar_width;
          height: $scrollbar_height;
        }
        &::-webkit-scrollbar-track {
          background: $light_gray;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $navy;
        }

        > .sidebar {
          height: 100%;
        }

        & ~ .drop_shadow {
          content: "";
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $black;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s ease;
          z-index: 4;
        }

        &.sidebar_show {
          left: 0;

          & ~ .drop_shadow {
            visibility: visible;
            opacity: 0.5;
          }
        }
      }
    }
    & > .row {
      & > div:not(.view-body__header) {
        &.order-3 {
          margin-bottom: 0 !important;
        }
        @media (min-width: 576px) {
          &.order-sm-3 {
            margin-bottom: 0 !important;
          }
        }
        @media (min-width: 768px) {
          &.order-md-3 {
            margin-bottom: 0 !important;
          }
        }
        @media (min-width: 992px) {
          &.order-lg-3 {
            margin-bottom: 0 !important;
          }
        }
        @media (min-width: 1200px) {
          &.order-xl-3 {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}
</style>
